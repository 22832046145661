// MenuButton.tsx
import React from 'react';

const MenuButton: React.FC = () => {
  return (
    <div className="menu-button">
      {/* Your menu button content goes here */}
      {/* <button>Menu</button> */}
    </div>
  );
};

export default MenuButton;
