import imageFileResizer from 'react-image-file-resizer';
import { PDFDocument } from 'pdf-lib';

export const resizeFile = async (
  file: File,
  fileType: string,
  compressionPercentage: number,
  compressionQuality: number = 100
): Promise<Blob> => {
  const maxWidth = 500;
  const maxHeight = 500;

  return new Promise(async (resolve, reject) => {
    if (fileType === 'pdf') {
      try {
        // Read the PDF file
        const pdfBytes = await file.arrayBuffer();
        const pdfDoc = await PDFDocument.load(pdfBytes);
        const compressedPdfBytes = await pdfDoc.save();

        const compressedPdfBlob = new Blob([compressedPdfBytes], { type: 'application/pdf' });

        resolve(compressedPdfBlob);
      } catch (error: any) {
        reject(new Error('Error compressing PDF file: ' + error.message));
      }
    } else {
      imageFileResizer.imageFileResizer(
        file,
        maxWidth,
        maxHeight,
        fileType,
        compressionQuality, // Adjust the compression quality here
        0,
        (compressedFile: any) => {
          if (compressedFile instanceof Blob) {
            resolve(compressedFile);
          } else {
            reject(new Error('Unexpected compressed file type'));
          }
        },
        'blob',
        0,
        0,
      );
    }
  });
};
