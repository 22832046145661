// App.tsx
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from '../src/components/HomePage/Home'; 
import AboutUs from './components/AboutUs'; 
import ContactUs from './components/contactUs'

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/contact-us" element={<ContactUs />} />
        {/* Add more routes as needed */}
      </Routes>
    </Router>
  );
};

export default App;
