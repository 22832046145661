// Home.tsx
import React from 'react';
import Header from './Header';
import Footer from './Footer';
import MenuButton from './MenuButton';
import CompressFile from './CompressFile';

const Home: React.FC = () => {
  return (
    <div>
      <Header />
      <MenuButton />
      <div className="content">
        <CompressFile />
      </div>
      <Footer />
    </div>
  );
};

export default Home;
