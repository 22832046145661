import React, { useState, DragEvent } from 'react';
import { saveAs } from 'file-saver';
import { resizeFile } from './utils';
import {
    Button,
    Container,
    Typography,
    Paper,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Slider
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';

const CompressFile: React.FC = () => {
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [selectedFileType, setSelectedFileType] = useState<string>('jpg');
    const [uploadedFileUrl, setUploadedFileUrl] = useState<string | undefined>(undefined);
    const [compressionPercentage, setCompressionPercentage] = useState<number>(50);

    const handleFileChange = (files: FileList | null) => {
        const fileArray: File[] = files ? Array.from(files) : [];
        if (fileArray.length > 0) {
            const selectedFileType = getFileType(fileArray[0]);
            if (selectedFileType === 'jpg' || selectedFileType === 'jpeg' || selectedFileType === 'pdf') {
                setSelectedFile(fileArray[0]);
                setSelectedFileType(selectedFileType);
                const fileUrl = URL.createObjectURL(fileArray[0]);
                setUploadedFileUrl(fileUrl);
            } else {
                alert('Please select a JPEG file.');
            }
        }
    };

    const handleSliderChange = (event: Event, value: number | number[]) => {
        setCompressionPercentage(value as number);
      };
      

    const getFileType = (file: File): string => {
        const fileNameParts = file.name.split('.');
        const fileExtension = fileNameParts[fileNameParts.length - 1].toLowerCase();
        return fileExtension;
    };

    const handleDrop = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        if (event.dataTransfer.files.length > 0) {
            handleFileChange(event.dataTransfer.files);
        }
    };

    const handleCompress = async () => {
        try {
            if (selectedFile) {
                const compressedFile = await resizeFile(selectedFile, selectedFileType, compressionPercentage);
                saveAs(compressedFile, `pkmweb_compressed.${selectedFileType}`);
            }
        } catch (error) {
            console.error('Error compressing file:', error);
        }
    };

    
    return (
        <Container style={{ margin: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Paper elevation={3} style={{ padding: '20px', marginTop: '20px', width: '80%' }}>
                <Typography variant="h5" gutterBottom style={{ textAlign: 'center' }}>
                    File Compression
                </Typography>
                <div
                    style={{
                        border: '2px dashed #aaa',
                        padding: '20px',
                        textAlign: 'center',
                        cursor: 'pointer',
                    }}
                    onDragOver={(e) => e.preventDefault()}
                    onDrop={handleDrop}
                >
                    <br />
                    <input
                        type="file"
                        onChange={(e) => handleFileChange(e.target.files)}
                        style={{ display: 'none' }}
                        accept=".jpg, .jpeg, .pdf"
                        id="file-upload"
                    />
                    <label htmlFor="file-upload">
                        <Button variant="contained" component="span">
                            Select File
                        </Button>
                    </label>
                    <Typography variant="body2" style={{ marginTop: '10px' }}>
                        Drag and drop files here or click to select.
                    </Typography>
                </div>
                <Typography variant="body2" style={{ marginTop: '10px' }}>
                    Compression Percentage: {compressionPercentage}%
                </Typography>
                <Slider
                    value={compressionPercentage}
                    onChange={handleSliderChange}
                    valueLabelDisplay="auto"
                    step={1}
                    marks
                    min={0}
                    max={100}
                />
                <br />
                <br />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleCompress}
                    style={{ textAlign: 'center' }}>
                    Compress
                </Button>
            </Paper>
            <div style={{ width: '80%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {selectedFile && (
                    <div>
                        <Typography variant="body2" style={{ marginTop: '10px', textAlign: 'center' }}>
                            Selected File: {selectedFile.name}
                        </Typography>
                        {/* Display uploaded file */}
                        {selectedFileType === 'jpg' || selectedFileType === 'jpeg' ? (
                            <img src={uploadedFileUrl} alt="Uploaded File" style={{ maxWidth: '100%', marginTop: '10px' }} />
                        ) : (
                            <a href={uploadedFileUrl} target="_blank" rel="noopener noreferrer" style={{ display: 'block', marginTop: '10px', textAlign: 'center' }}>
                                View Uploaded File
                            </a>
                        )}
                    </div>
                )}
            </div>
        </Container>
    );
};

export default CompressFile;
