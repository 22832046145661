// Header.tsx
import React from 'react';
import { Link } from 'react-router-dom';
import './Home.css';

const Header: React.FC = () => {
  return (
    <header>
      <div className="header-container">
        <div>
          <h1>pkmweb</h1>
        </div>

        {/* <div className="header-buttons">
          <button>Home</button>
          <Link to="/about-us">
            <button>About us</button>
          </Link>
          <Link to="/contact-us">
          <button>Contact us</button>
          </Link>
        </div> */}
        
      </div>
    </header>
  );
};

export default Header;
