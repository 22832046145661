// Footer.tsx
import React from 'react';
import './Home.css'

const Footer: React.FC = () => {
  return (
    <footer>
      <div className="footer-content">
        {/* Your footer content goes here */}
        <p>&copy; 2023 pkmweb. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
