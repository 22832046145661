// AboutUs.tsx
import React from 'react';

const AboutUs: React.FC = () => {
  return (
    <div>
      <h2>About Us</h2>
      {/* Add content for About Us page */}
    </div>
  );
};

export default AboutUs;
